import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    Paper,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    TablePagination,
    TableSortLabel,
    Button,
    MenuItem,
    Menu,
    TableContainer,
    Divider,
} from '@mui/material';

import { useMessages } from '../../../hooks/useMessages';
import {
    executeConsumerDelete,
    executeResetPassword,
} from '../../../store/actions/profileActions';
import {
    setOrder,
    setOrderBy,
    setPage,
    setRowsPerPage,
} from '../../../store/actions/searchActions';
import { getPasswordResetPayloadSearch } from '../../../services/consumerApiRequest';
import { formatDateEx } from '../../../services/formatUtils';
import { dialogShow } from '../../../store/actions/dialogActions';
import * as C from '../../../config/marketConfig';

const ROWS_PER_PAGE = [10, 25, 50];

const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};

const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => comparator(a, b, orderBy)
        : (a, b) => -comparator(a, b, orderBy);
};

const comparator = (a, b, orderBy) => {
    if (b.profile[orderBy] < a.profile[orderBy]) {
        return -1;
    }
    if (b.profile[orderBy] > a.profile[orderBy]) {
        return 1;
    }
    return 0;
};

const SearchResults = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { L } = useMessages();

    const search = useSelector((state) => state.search);

    const { user } = useSelector((state) => state.auth);
    const { market, hasConsumerUpdate } = user || {};

    const dateFormat = useMemo(
        () => C.getMarketValue(C.MARKET_FORMAT_DATE, market).fmt,
        [market]
    );

    const canResetMarket = useMemo(
        () => C.getMarketValue(C.MARKET_ACTIONS.resetPassword, market),
        [market]
    );

    const canDeleteMarket = useMemo(
        () => C.getMarketValue(C.MARKET_ACTIONS.deleteConsumer, market),
        [market]
    );

    const menuLabels = useMemo(
        () => ({
            view_profile: L.searchpage_menu_view_profile,
            age_verification: L.searchpage_menu_age_verification,
            marketing_prefs: C.MV(
                C.MARKET_LABELS.tabMarketingPreferences,
                market,
                L
            ),
            password_reset: L.searchpage_menu_password_reset,
            consumer_delete: L.searchpage_menu_consumer_delete,
        }),
        [L, market]
    );

    const canReset = canResetMarket && hasConsumerUpdate;
    const canDelete = canDeleteMarket && hasConsumerUpdate;

    const [anchorEls, setAnchorEls] = useState([]);

    const handleMenuOpen = (e, id) => {
        const els = [...anchorEls];
        els[id] = e.currentTarget;
        setAnchorEls(els);
    };

    const handleMenuClose = (id) => {
        const els = [...anchorEls];
        els[id] = null;
        setAnchorEls(els);
    };

    const headers = useMemo(
        () => [
            { id: 'firstName', label: L.searchpage_column_first_name },
            { id: 'lastName', label: L.searchpage_column_last_name },
            {
                id: 'address',
                label: L.searchpage_column_address,
                disableSorting: true,
            },
            { id: 'dateOfBirth', label: L.searchpage_column_dob },
            { id: 'email', label: L.searchpage_column_email },
            { id: 'phone', label: L.searchpage_column_phone },
            {
                id: 'actions',
                label: L.searchpage_column_actions,
                disableSorting: true,
            },
        ],
        [L]
    );

    const handleChangePage = (_, page) => {
        dispatch(setPage(page));
    };

    const handleChangeRowsPerPage = (event) => {
        dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
        dispatch(setPage(0));
    };

    const recordsSorted = useMemo(() => {
        return stableSort(
            search.result || [],
            getComparator(search.order, search.orderBy)
        ).slice(
            search.page * search.rowsPerPage,
            (search.page + 1) * search.rowsPerPage
        );
    }, [
        search.result,
        search.order,
        search.orderBy,
        search.page,
        search.rowsPerPage,
    ]);

    const handleSortRequest = (cellId) => {
        const isAsc = search.orderBy === cellId && search.order === 'asc';
        dispatch(setOrder(isAsc ? 'desc' : 'asc'));
        dispatch(setOrderBy(cellId));
    };

    const handleInfoUser = (consumerId) => {
        navigate(`/consumer/${consumerId}/system`);
    };

    const handleAgeVerificationUser = (consumerId) => {
        navigate(`/consumer/${consumerId}/ageverification`);
    };

    const handleMarketingPreferences = (consumerId) => {
        navigate(`/consumer/${consumerId}/marketingpreferences`);
    };

    const handlePasswordReset = (consumerId, profile) => {
        handleMenuClose(consumerId);
        dispatch(
            dialogShow(
                L.dialog_password_reset_title,
                L.dialog_password_reset_text,
                () => {
                    const params = getPasswordResetPayloadSearch(
                        profile,
                        market
                    );
                    dispatch(executeResetPassword(params));
                }
            )
        );
    };

    const handleConsumerDelete = (consumerId) => {
        handleMenuClose(consumerId);
        dispatch(
            dialogShow(
                L.dialog_consumer_delete_title,
                L.dialog_consumer_delete_text,
                () => dispatch(executeConsumerDelete(consumerId))
            )
        );
    };

    return (
        <Paper sx={{ width: '100%' }}>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {headers.map((header) => (
                                <TableCell
                                    sx={{
                                        backgroundColor: (theme) =>
                                            theme.palette.tabHeader.main,
                                    }}
                                    key={header.id}
                                    sortDirection={
                                        search.orderBy === header.id
                                            ? search.order
                                            : false
                                    }
                                >
                                    {header.disableSorting ? (
                                        header.label
                                    ) : (
                                        <TableSortLabel
                                            active={
                                                search.orderBy === header.id
                                            }
                                            direction={
                                                search.orderBy === header.id
                                                    ? search.order
                                                    : 'asc'
                                            }
                                            onClick={() =>
                                                handleSortRequest(header.id)
                                            }
                                        >
                                            {header.label}
                                        </TableSortLabel>
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {recordsSorted.map((item) => {
                            const sx = !item.profile2?.isActive
                                ? {
                                      color: (theme) =>
                                          theme.palette.inactiveRecord.main,
                                  }
                                : null;
                            return (
                                <TableRow
                                    key={item.UID}
                                    sx={{
                                        cursor: 'pointer',
                                        '&:hover': {
                                            backgroundColor: (theme) =>
                                                theme.palette.hoverBackground
                                                    .main,
                                        },
                                    }}
                                >
                                    <TableCell
                                        onClick={() => handleInfoUser(item.UID)}
                                        sx={sx}
                                    >
                                        {item.profile.firstName}
                                    </TableCell>
                                    <TableCell
                                        onClick={() => handleInfoUser(item.UID)}
                                        sx={sx}
                                    >
                                        {item.profile.lastName}
                                    </TableCell>
                                    <TableCell
                                        onClick={() => handleInfoUser(item.UID)}
                                        sx={sx}
                                    >
                                        {item.profile.address2}
                                    </TableCell>
                                    <TableCell
                                        onClick={() => handleInfoUser(item.UID)}
                                        sx={sx}
                                    >
                                        {formatDateEx(
                                            dateFormat,
                                            item.profile.dateOfBirth
                                        )}
                                    </TableCell>
                                    <TableCell
                                        onClick={() => handleInfoUser(item.UID)}
                                        sx={sx}
                                    >
                                        {item.profile.email}
                                    </TableCell>
                                    <TableCell
                                        onClick={() => handleInfoUser(item.UID)}
                                        sx={sx}
                                    >
                                        {item.profile.phone}
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            onClick={(e) =>
                                                handleMenuOpen(e, item.UID)
                                            }
                                        >
                                            ...
                                        </Button>
                                        <Menu
                                            id={item.UID}
                                            anchorEl={anchorEls[item.UID]}
                                            open={Boolean(anchorEls[item.UID])}
                                            onClose={(e) =>
                                                handleMenuClose(item.UID)
                                            }
                                        >
                                            <MenuItem
                                                sx={{ fontWeight: 'bold' }}
                                                onClick={() =>
                                                    handleInfoUser(item.UID)
                                                }
                                            >
                                                {menuLabels.view_profile}
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() =>
                                                    handleAgeVerificationUser(
                                                        item.UID
                                                    )
                                                }
                                            >
                                                {menuLabels.age_verification}
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() =>
                                                    handleMarketingPreferences(
                                                        item.UID
                                                    )
                                                }
                                            >
                                                {menuLabels.marketing_prefs}
                                            </MenuItem>
                                            {canResetMarket && (
                                                <MenuItem
                                                    onClick={() =>
                                                        handlePasswordReset(
                                                            item.UID,
                                                            item.profile
                                                        )
                                                    }
                                                    disabled={!canReset}
                                                >
                                                    {menuLabels.password_reset}
                                                </MenuItem>
                                            )}
                                            {canDeleteMarket && <Divider />}
                                            {canDeleteMarket && (
                                                <MenuItem
                                                    onClick={() =>
                                                        handleConsumerDelete(
                                                            item.UID
                                                        )
                                                    }
                                                    disabled={!canDelete}
                                                >
                                                    {menuLabels.consumer_delete}
                                                </MenuItem>
                                            )}
                                        </Menu>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                page={search.page}
                rowsPerPageOptions={ROWS_PER_PAGE}
                rowsPerPage={search.rowsPerPage}
                count={search.result ? search.result.length : 0}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
};

export default SearchResults;
