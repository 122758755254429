import React, { useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Box, Tabs, Tab } from '@mui/material';

import TabSystem from './TabSystem';
import TabPersonal from './TabPersonal';
import TabAddressData from './TabAddressData';
import TabMarketingPreferences from './TabMarketingPreferences';
import TabAgeVerification from './TabAgeVerification';
import TabLoyalty from './TabLoyalty';

import { dialogShow, dialogClose } from '../../../store/actions/dialogActions';
import { profileEditMode } from '../../../store/actions/profileActions';
import { useMessages } from '../../../hooks/useMessages';
import * as DM from '../../../services/dataModel';
import * as C from '../../../config/marketConfig';

const createTabs = (L, market) => {
    const tabs = [
        {
            id: 'system',
            label: L.consumerpage_tab_system,
            component: TabSystem,
        },
        {
            id: 'personal',
            label: L.consumerpage_tab_personal,
            component: TabPersonal,
        },
        {
            id: 'addressdata',
            label: L.consumerpage_tab_address,
            component: TabAddressData,
        },
        {
            id: 'marketingpreferences',
            label: C.MV(C.MARKET_LABELS.tabMarketingPreferences, market, L),
            component: TabMarketingPreferences,
        },
        {
            id: 'ageverification',
            label: L.consumerpage_tab_age_verification,
            component: TabAgeVerification,
        },
        {
            id: 'loyalty',
            label: L.consumerpage_tab_loyalty_data,
            component: TabLoyalty,
        },
    ];

    return tabs.filter((tab) => C.MV(C.MARKET_TABS[tab.id], market));
};

const TabsBox = ({ model }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id, tab } = useParams();
    const { L, getMessage } = useMessages();

    const { user } = useSelector((state) => state.auth);
    const { market } = user || {};

    const visibleTabs = useMemo(() => createTabs(L, market), [L, market]);

    const activeTab = useMemo(
        () => visibleTabs.find((item) => item.id === tab) || visibleTabs[0],
        [visibleTabs, tab]
    );

    const TabComponent = activeTab?.component;

    const handleChangeTab = (_, tab) => {
        const hasChanges = DM.hasChanges(model);
        const hasErrors = DM.hasErrors(model);
        if (hasChanges || hasErrors) {
            dispatch(
                dialogShow(
                    L.dialog_unsaved_changes_title,
                    L.dialog_unsaved_changes_text,
                    () => {
                        dispatch(dialogClose());
                        dispatch(profileEditMode(false));
                        navigate(`/consumer/${id}/${tab}`);
                    }
                )
            );
        } else {
            navigate(`/consumer/${id}/${tab}`);
        }
    };

    return (
        <Container>
            <Box>
                <Tabs
                    value={activeTab?.id}
                    variant="fullWidth"
                    onChange={handleChangeTab}
                >
                    {visibleTabs.map((tab) => (
                        <Tab
                            key={tab.id}
                            label={getMessage(tab.label)}
                            value={tab.id}
                        />
                    ))}
                </Tabs>
            </Box>
            <Box>
                <TabComponent model={model} />
            </Box>
        </Container>
    );
};

export default TabsBox;
