// Market configuration

/* eslint-disable no-useless-escape */

import { customFieldPH } from './customPH';

export const MARKET_REGEXP_EMAIL = {
    DEFAULT: /^[^\s@]+@[^\s\.@]+(\.[^\s\.@]+)+$/,
};

export const MARKET_REGEXP_MOBILE = {
    DEFAULT: /^\d{9,15}$/,
    CH: /^07[4-9]\d{7}$/,
    DE: [
        [/^01609/, /^01609\d{7}$/],
        [/^0176/, /^0176\d{8}$/],
        [/^0/, /^0(15\d{9}|16\d{8}|17\d{8})$/],
    ],
    PH: /^\+63[8,9]\d{9}$/,
    ID: /^\d{7,14}$/,
    UA: /^\+380\d{9}$/,
};

export const MARKET_REGEXP_CITY = {
    DEFAULT: /^.{0,40}$/,
    PH: /^.{0,50}$/,
};

export const MARKET_REGEXP_PROVINCE = {
    DEFAULT: /^.{0,50}$/,
};

export const MARKET_REGEXP_POSTAL = {
    DEFAULT: /^[0-9]{0,20}$/,
    DE: /^[0-9]{5}$/,
    PH: /^[0-9]{4}$/,
    ID: /^[0-9]{5}$/,
    AR: /^[A-Z]?[1-9][0-9]{3}$/,
    UA: /^[0-9]{5}$/,
};

export const MARKET_VALUES_COUNTRY = {
    CH: ['CH'],
    DE: ['DE'],
    EC: ['EC'],
    PH: ['PH'],
    ID: ['ID'],
    PS: ['PS'],
    IQ: ['IQ'],
    AR: ['AR'],
    EG: ['EG'],
    LB: ['LB'],
    UA: ['UA'],
    MA: ['MA'],
    GE: ['GE'],
};

export const MARKET_VALUES_LANGUAGE = {
    CH: ['de', 'fr'],
    DE: ['de'],
    EC: ['es'],
    PH: ['en', 'ph'],
    ID: ['id'],
    PS: ['en', 'ar'],
    IQ: ['en', 'ar', 'ku'],
    AR: ['es'],
    EG: ['ar'],
    LB: ['ar'],
    UA: ['uk'],
    MA: ['ar', 'fr'],
    GE: ['ka'],
};

export const MARKET_VALUES_BLOCK_REASON = {
    ID: ['FRAUDULENT', 'DEACTIVATED', 'LAS_REQUEST', 'EMPLOYEE_TPA'],
};

export const MARKET_VALUES_AV_SOFT_METHODS = {
    DEFAULT: [],
    PH: ['FACECAPTURE'],
};

export const MARKET_VALUES_LOYALTY_BRANDS = {
    DEFAULT: [],
    ID: ['MARLBORO'],
    AR: ['MARLBORO'],
};

export const MARKET_FORMAT_DATE = {
    DEFAULT: { fmt: 'DD-MM-YYYY', mask: 'DMY' },
    PH: { fmt: 'MM/DD/YYYY', mask: 'MDY' },
    ID: { fmt: 'DD/MM/YYYY', mask: 'DMY' },
    MA: { fmt: 'DD/MM/YYYY', mask: 'DMY' },
    GE: { fmt: 'DD/MM/YYYY', mask: 'DMY' },
};

export const MARKET_FORMAT_DATETIME = {
    DEFAULT: 'DD-MM-YYYY HH:mm',
    PH: 'MM/DD/YYYY hh:mm A',
    ID: 'DD/MM/YYYY hh:mm A',
    MA: 'DD/MM/YYYY hh:mm A',
    GE: 'DD/MM/YYYY hh:mm A',
};

export const MARKET_TABS = {
    system: { DEFAULT: true },
    personal: { DEFAULT: true },
    addressdata: { DEFAULT: true, MA: false, GE: false },
    marketingpreferences: { DEFAULT: true },
    ageverification: { DEFAULT: true },
    loyalty: { DEFAULT: false, ID: true, AR: true },
};

export const MARKET_ACTIONS = {
    resetPassword: {
        DEFAULT: true,
        DE: false,
        UA: false,
        MA: false,
        GE: false,
    },
    blockConsumer: {
        DEFAULT: false,
        PH: true,
        ID: true,
    },
    deleteConsumer: {
        DEFAULT: true,
        AR: false,
    },
    havUpdate: {
        DEFAULT: true,
        DE: false,
        AR: false,
        UA: false,
        MA: false,
        GE: false,
    },
};

export const MARKET_CONFIRMATIONS = {
    nameChange: {
        DEFAULT: true,
        MA: false,
        GE: false,
    },
};

export const MARKET_CONTACT = {
    DEFAULT: { email: true },
    PH: { mobile: true },
    MA: { mobile: true },
    GE: { mobile: true },
};

export const MARKET_FIELDS = {
    accountLocked: {
        DEFAULT: { visible: false },
        ID: { visible: true },
    },
    firstName: {
        DEFAULT: { visible: true, editable: false, required: false },
        PH: customFieldPH,
        ID: { visible: true, editable: true, required: true },
        AR: { visible: true, editable: true, required: true },
        MA: { visible: true, editable: true, required: true },
    },
    lastName: {
        DEFAULT: { visible: true, editable: true, required: true },
        ID: { visible: false },
        UA: { visible: true, editable: false, required: false },
    },
    dob: {
        DEFAULT: { visible: true, editable: false, required: false },
        PH: customFieldPH,
    },
    gender: {
        DEFAULT: { editable: true, required: true },
        MA: { editable: false, required: false },
        GE: { editable: false, required: false },
    },
    email: {
        DEFAULT: { editable: false, required: false },
        CH: { editable: true, required: true },
        PH: { editable: true, required: false },
        ID: { editable: true, required: true },
        AR: { editable: true, required: true },
        GE: { editable: true, required: false },
    },
    language: {
        DEFAULT: { editable: true, required: true },
        MA: { editable: false, required: false },
    },
    mobilePhone: {
        DEFAULT: { editable: true, required: false },
        PH: { editable: false, required: false },
        ID: { editable: false, required: false },
        UA: { editable: false, required: false },
        MA: { editable: false, required: false },
        GE: { editable: false, required: false },
    },
    addrProvince: {
        DEFAULT: { visible: false, required: false },
        PH: { visible: true, required: true },
        ID: { visible: true, required: true },
    },
    addrLine1: {
        DEFAULT: { required: true },
        PH: { required: false },
        ID: { required: false },
    },
    addrLine2: {
        DEFAULT: { required: false },
    },
    addrPostal: {
        DEFAULT: { required: true },
        PH: { required: false },
        ID: { required: false },
    },
};

export const MARKET_FIELDS_MAXLEN = {
    DEFAULT: {
        DEFAULT: 250,
    },
};

export const MARKET_LABELS = {
    tabMarketingPreferences: {
        DEFAULT: (L) => L.consumerpage_tab_marketing_prefs,
        GE: (L) => L.consumerpage_tab_communication_prefs,
    },
    firstName: {
        DEFAULT: (L) => L.consumerpage_personal_field_first_name,
        ID: (L) => L.consumerpage_personal_field_full_name,
    },
    brandSegm: {
        DEFAULT: (L) => L.consumerpage_marketing_section_brand_seg,
        ID: (L) => L.consumerpage_marketing_section_brand_pref,
    },
    setHavConfirmation: {
        DEFAULT: null,
        ID: (L) => L.dialog_set_hav_status_confirm,
    },
};

export const MARKET_CONFIG_BRANDS = {
    DEFAULT: {
        brand: { editable: true, required: true },
        subBrand: { editable: true, required: true },
    },
    DE: { brand: { editable: false } },
    EC: { brand: { editable: false } },
    MA: {},
};

export const MARKET_CONFIG_CHANNEL_OPTINS = {
    DEFAULT: { email: true, sms: true, call: true, dm: true },
    MA: { email: false, sms: true, call: true, dm: false },
    GE: { email: true, sms: true, call: true, dm: false },
};

export const MARKET_CONFIG_BRAND_OPTINS = {
    DEFAULT: {
        singleOptin: true,
        brands: ['MARLBORO'],
    },
    CH: {
        singleOptin: true,
        brands: ['MARLBORO', 'CHESTERFIELD'],
    },
    PH: {
        singleOptin: false,
        brands: ['MARLBORO', 'CHESTERFIELD', 'FORTUNE'],
    },
    ID: {
        singleOptin: false,
        brands: [
            'AMILD',
            'AULTRAMILD',
            'DJISAMSOE',
            'MAGNUM',
            'MARLBORO',
            'SAMPOERNA KRETEK',
        ],
    },
    AR: {
        singleOptin: true,
        brands: ['MARLBORO', 'CHESTERFIELD', 'PHILIPMORRIS'],
    },
    UA: {
        singleOptin: true,
        brands: [
            'BONDSTREET',
            'CHESTERFIELD',
            'LM',
            'MARLBORO',
            'PARLIAMENT',
            'PHILIPMORRIS',
            'OTHERS',
        ],
    },
    MA: {
        singleOptin: true,
        brands: ['PHILIPMORRIS'],
    },
    GE: {
        singleOptin: true,
        brands: ['MARLBORO', 'PARLIAMENT', 'LM'],
    },
};

export const MARKET_SEARCH_LIMIT = {
    DEFAULT: null,
    EC: 100,
};

export function getMarketValue(valueMap, market, ctx = null) {
    const value =
        valueMap && Object.hasOwn(valueMap, market)
            ? valueMap[market]
            : valueMap.DEFAULT;
    return value instanceof Function ? value(ctx) : value;
}

export function MV(valueMap, market, ctx = null) {
    return getMarketValue(valueMap, market, ctx);
}
